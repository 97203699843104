<template>
  <div class="books-export">
    <b-card>
      <div class="floating-export" v-if="showExport">
        <button class="close" @click="showExport = false">
          <i class="bx bx-x"></i>
        </button>

        <spinner color="white" v-if="loading" />
        <div class="d-flex align-items-center justify-content-center" v-else>
          <p class="mb-0 text-white mr-3">
            {{ $t("book-count", { count: book_ids.length }) }}
          </p>
          <ebp-button v-if="book_ids.length" size="sm" @click="exportCSV">
            {{ $t("export-csv") }}
          </ebp-button>
        </div>
      </div>
      <form-generator
        :elements="elements"
        :data="filters"
        :handleUpdate="({ key }, v) => (filters[key] = v)"
      />
    </b-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { mapValues } from "lodash-es";
import bookHelper from "@/helpers/book";

import FormGenerator from "../../../components/form-generator.vue";

import BookTypes from "@/fixtures/book-types";
import BookStatuses from "@/fixtures/book-statuses";

export default {
  name: "books-export",
  components: {
    FormGenerator
  },
  data() {
    return {
      filters: {
        book_types: [],
        statuses: [],
        is_changed: null,
        title: null,
        publisher_name: null,
        sales_channel_ids: [],
        category_ids: [],
        min_price: null,
        max_price: null,
        language_ids: [],
        user_ids: [],
        author: null
      },
      loading: false,
      loadingUsers: false,
      users: [],
      book_ids: [],
      showExport: false,
      send_to_new_channels_in_network: null
    };
  },
  mounted() {
    this.$store.dispatch("ancillaries/getLanguages");
    this.$store.dispatch("ancillaries/getSalesChannels");
    this.$store.dispatch("ancillaries/getBookCategories");
  },
  computed: {
    ...mapState("ancillaries", [
      "languages",
      "salesChannelsCategorized",
      "bookCategories"
    ]),
    elements() {
      return [
        {
          key: "user_ids",
          label: "client",
          type: "multiselect",
          placeholder: "no-client-selected",
          attrs: {
            loading: this.loadingUsers,
            options: this.users,
            trackBy: "id",
            label: "name"
          },
          events: {
            "search-change": this.search
          }
        },
        {
          key: "book_types",
          label: "book-type",
          type: "multiselect",
          attrs: {
            options: this.optionize(BookTypes),
            label: "text",
            trackBy: "value"
          }
        },
        {
          key: "statuses",
          label: "status",
          type: "multiselect",
          attrs: {
            options: this.optionize(BookStatuses),
            label: "text",
            trackBy: "value"
          }
        },
        {
          key: "language_ids",
          label: "language",
          type: "multiselect",
          attrs: {
            options: this.optionize(this.languages, "id", "name"),
            label: "text",
            trackBy: "value"
          }
        },
        {
          key: "sales_channel_ids",
          label: "sales-channels",
          type: "tree-select",
          attrs: {
            options:
              bookHelper.optionizeChannels(this.salesChannelsCategorized) || [],
            trackBy: "name",
            label: "name"
          }
        },
        {
          key: "category_ids",
          label: "categories",
          type: "multiselect",
          attrs: {
            options: this.bookCategories,
            trackBy: "id",
            label: "name",
            customLabel({ name, code }) {
              return `${name} (${code})`;
            }
          }
        },
        {
          key: "title",
          label: "book-title",
          type: "string"
        },
        {
          key: "publisher_name",
          label: "publisher-name",
          type: "string"
        },
        {
          key: "author",
          label: "author",
          type: "string"
        },
        {
          key: "is_changed",
          label: "is-updated",
          type: "multiselect",
          placeholder: "acknowledged-unacknowledged-changes",
          attrs: {
            options: [
              { text: "All", value: null },
              { text: "Yes", value: true },
              { text: "No", value: false }
            ],
            label: "text",
            trackBy: "value"
          }
        },
        {
          key: "send_to_new_channels_in_network",
          label: "send_to_new_channels_in_network",
          type: "select",
          placeholder: "send_to_new_channels_in_network",
          attrs: {
            options: [
              { text: "All", value: null },
              { text: "Yes", value: true },
              { text: "No", value: false }
            ],
            label: "text",
            trackBy: "value"
          }
        },
        {
          small: true,
          key: "min_price",
          label: "min-price",
          type: "number"
        },
        {
          small: true,
          key: "max_price",
          label: "max-price",
          type: "number"
        },
        {
          type: "button",
          text: "load-books",
          className: "mx-4 float-right",
          attrs: {
            loading: this.loading
          },
          events: {
            click: this.get
          }
        }
      ];
    }
  },
  methods: {
    async exportCSV() {
      this.loading = true;

      try {
        await this.$store.dispatch("books/exportCSV", this.book_ids);
        this.showExport = false;
      } catch (err) {
        console.error(err);
      }

      this.loading = false;
    },
    async search(q) {
      if (q) {
        this.loading = true;
        try {
          const res = await this.$store.dispatch("users/search", {
            search: q
          });
          this.users = res.data.data;
        } catch (err) {
          console.error(err);
        }
        this.loading = false;
      }
    },
    async get() {
      this.loading = true;
      this.showExport = true;

      // extract tree select ids
      const sales_channel_ids = bookHelper.extractChannelIds(
        this.filters.sales_channel_ids
      );

      try {
        const res = await this.$store.dispatch("books/get", {
          ...mapValues({ ...this.filters, sales_channel_ids }, v =>
            Array.isArray(v)
              ? v.length
                ? v
                    .map(v => (typeof v === "object" ? v.id || v.value : v))
                    .join(",")
                : null
              : v
          ),
          ids_only: true
        });
        this.book_ids = res.data;
      } catch (err) {
        console.error(err);
      }
      this.loading = false;
    }
  }
};
</script>

<style lang="scss">
.books-export {
  .card-body {
    position: relative;

    .floating-export {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.9);
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 2;
      border-radius: 5px;

      .close {
        position: absolute;
        right: 10px;
        top: 10px;
        color: white;
        font-size: 30px;
        background: none;
        border: none;
        z-index: 3;
      }
    }
  }
}
</style>
